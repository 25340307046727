.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loading {
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}