.header {
    margin-bottom: 20px;
}

.button {
    border-radius: 50px !important;
    background-color: #625DF5 !important;
    color: #ffffff !important;
    text-transform: none !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.button:disabled {
    text-transform: none !important;
    border: 1px solid #cccccc !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    margin-left: 10px;
    margin-right: 10px;

}

.error {
    color: rgb(241, 76, 16) !important;
}

.pending {
    display: inline-block;
    border-radius: 2%;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(194, 221, 192, 255);
    color: rgb(111, 78, 78);
}

.processing {
    display: inline-block;
    background-color: rgb(2, 217, 27);
    border-radius: 2%;
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(10, 90, 43);
}

.completed {
    display: inline-block;
    background-color: rgb(160, 123, 255);
    border-radius: 2%;
    padding-left: 10px;
    padding-right: 10px;
    color: #ffffff;
}


.rejected {
    display: inline-block;
    background-color: rgb(255, 2, 2);
    border-radius: 2%;
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(255, 255, 255);
}