.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    /* align-items: center; */
}

.signin {
    font-size: 25px;
    font-weight: bolder;
}

.button {
    border-radius: 50px !important;
    background-color: #625DF5 !important;
    height: 60px;
    color: #ffffff !important;
}

.link {
    display: flex;
    padding-top: 5px;
}