.graphHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.revenue {
    font: normal normal bold 20px/24px Helvetica;
    color: #020C1D;
}

.amountContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.amount {
    font: normal normal bold 20px/24px Helvetica;
    color: #020C1D;
}

.duration {
    font: normal normal normal 14px/17px Helvetica;
    color: #777777;
}

.options {
    font: normal normal normal 18px/22px Helvetica;
    color: #000000;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C1C6D3;
    border-radius: 24px !important;
}