.card {
    display: flex;
    flex-direction: column;
    background-color: var(--custom-background-color);
    border: var(--custom-border-width);
    padding-left: 20px;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-bottom: 20px;
    border-radius: 30px;
}

.header {
    margin: 0;
    padding: 0;
    font: normal normal bold 20px/24px Helvetica;
    color: #020C1D;
}

.helperHeader {
    margin: 0;
    padding: 0;
    font: normal normal normal 16px/19px Helvetica;
    color: #020C1D;
}

.count {
    font: normal normal normal 60px/72px Helvetica;
    letter-spacing: 0px;
    color: #020C1D;
}

.footer {
    display: flex;
    align-items: center;
}

.icon {
    display: flex;
    justify-content: center;
    background-color: var(--custom-icon-background-color);
    padding: 5px;
    margin-right: 3px;
    border-radius: 50px;
    width: 20px;
    height: 20px;
}



.serviceContainer {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ebeaed;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEAED;
    border-radius: 30px;
    padding: 20px;
}

.serviceKey {
    text-align: left;
    font: normal normal normal 20px/24px Helvetica;
    color: #656569;
}

.serviceValue {
    font: normal normal bold 20px/24px Helvetica;
    color: #000000;
}


.serviceSection {
    padding-bottom: 20px;
}

.seeMoreLink {
    display: flex;
    align-items: center;
    font: normal normal normal 20px/24px Helvetica Neue;
    color: #625DF5;
}

.serviceUrlValue {
    display: flex;
    align-items: center;
    font: normal normal bold 20px/24px Helvetica;
    color: #000000;
    /* clip text  */
    /* width: 80;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    text-transform: none; */
}

.serviceIconBesideText {
    padding-right: 5px;
    /* clip text */

}