.form__input input {
  height: 20px;
}

.label_field {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 8px;
}

.select_field {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 8px;
}

.select_label {
  padding-right: 10px;
}