.container {
  display: flex;
  align-items: center;
  background-color: #F8F8F8;
  height: 100%;
}

.sidebar {
  border-right: 0px !important;
}

.sidebar__menu {
  display: flex;
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.sidebar__nav a {
  font-size: 18px;
  display: flex;
  text-decoration: none;
  width: 100%;

}

.sidebar__nav a:hover {
  background: #e3e3e3 !important;
}

.sidebar__nav_item {
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-left: 20px;
}

.sidebar__nav_item span {
  padding-left: 25px;
}

.sidebar__active_link {
  color: #625df5 !important;
  background-color: #ffffff;
  margin-left: 20%;
  margin-right: 10%;
  border-radius: 34px;
}

.sidebar__link {
  color: #000000 !important;
  margin-left: 20%;
  margin-right: 10%;
  border-radius: 34px;
}