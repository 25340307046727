.delete_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.break {
    margin-bottom: 20px;
}

.image {
    width: 50%;
    height: 100%
}

.button {
    border-radius: 50px !important;
    background-color: #625DF5 !important;
    color: #ffffff !important;
    text-transform: none !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.button:disabled {
    text-transform: none !important;
    border: 1px solid #cccccc !important;
    background-color: #cccccc !important;
    color: #666666 !important;
}

.delete {
    color: #ff0200;
}

.delete_disabled {
    color: #ff00003c;
}

.edit {
    color: #02bcd4
}

.edit_disabled {
    color: #02bbd45e
}

.view {
    color: #4e53ef;
}