body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
}

div#root {
  height: 100%;
}

#error-page {
  background: url("../src/assets/images/404-page.svg") no-repeat center center fixed;
  -webkit-background-size: co1ver;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100dvh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#login-page {
  background: url("../src/assets/images/refund.jpg") no-repeat center center fixed;
  -webkit-background-size: co1ver;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100dvh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#btn {
  background-color: #1c87c9;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  border: none;
  color: #eeeeee;
  display: inline-block;
  font-size: 20px;
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
}