.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.main {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 50px 30px 50px 30px;
}

.signin {
    font-size: 25px;
    font-weight: bolder;
}

.button {
    border-radius: 50px !important;
    background-color: #625DF5 !important;
    height: 60px;
    color: #ffffff !important;
}