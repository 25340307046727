.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.username {
    padding-right: 10px;
    font-size: 20px;
}

.text {
    font-size: 25px;
}

hr {
    border: none;
    color: #eee;
    height: 1px;
    background-color: #eee
}

.logout {
    color: #f50057;
    display: flex;
    justify-content: center
}