.container {
    display: flex;
    height: 100%;
}

.outlet {
    overflow-x: scroll;
    width: 100%;
    margin-left: 40px;
    margin-right: 40px;
}