.header {
    margin-bottom: 20px;
}

.card {
    display: flex;
    justify-content: center;
}

.textField {
    border: 1px solid #E2E2E2;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
}

.actions {
    border-radius: 10px;
    box-shadow: 0px 2px 2px #dadada;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 50px;
}

.document {
    display: flex;
    align-items: center;
}

.view {
    padding-left: 10px;
}

.active,
.admin {
    display: inline-block;
    background-color: rgb(127, 255, 142);
    border-radius: 2%;
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(29, 65, 44);
}

.inactive,
.notAdmin {
    display: inline-block;
    border-radius: 2%;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(194, 221, 192, 255);
    color: red;
}