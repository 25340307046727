.calendarWrapper {
    padding: 16px
}

.popoverModal {
    display: flex;
    flex-direction: column;
}

.okayButton {
    display: flex;
    justify-content: flex-end;
}

.options {
    font: normal normal normal 18px/22px Helvetica;
    color: #000000 !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C1C6D3 !important;
    border-radius: 24px !important;
}

.datePreview {
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;

}

hr {
    border: none;
    color: #eee;
    height: 1px;
    background-color: #eee
}

.text {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    text-transform: none;
}